@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "Poppins", sans-serif !important;
  font-weight: 300 !important;
}
.header-thin {
  font-weight: 100 !important;
  font-family: "Poppins", sans-serif !important;
}

.header-extraLight {
  font-weight: 200 !important;
  font-family: "Poppins", sans-serif !important;
}
.header-light {
  font-weight: 300 !important;
  font-family: "Poppins", sans-serif !important;
}
.header-regular {
  font-weight: 400 !important;
  font-family: "Poppins", sans-serif !important;
}
.header-medium {
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}
.header-semiBold {
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
}

.header-bold {
  font-weight: 700 !important;
  font-family: "Poppins", sans-serif !important;
}
.header-extraBold {
  font-weight: 800 !important;
  font-family: "Poppins", sans-serif !important;
}
.header-black {
  font-weight: 900 !important;
  font-family: "Poppins", sans-serif !important;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #292727;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #972121;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b90303;
  cursor: pointer;
}
input {
  color: #fff !important;
}
::placeholder {
  color: #ccc !important;
}
.border-gray-600 {
  border-color: rgb(75 85 99) !important;
}
.border-gray-500 {
  border-color: rgb(107 114 128) !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.border-vgraydark {
  border-color: rgb(38 41 46 / var(--tw-border-opacity)) !important;
}
.group:hover .group-hover\:text-vorange {
  --tw-text-opacity: 1;
  color: rgb(255 50 0 / var(--tw-text-opacity)) !important;
}
.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
}
.text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}
.w-20 {
  width: 5rem !important;
}
.w-52 {
  width: 13rem !important;
}
.w-40 {
  width: 10rem !important;
}
.shimmer-table-row--header {
  display: none !important;
}

.shimmer-table-row {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 25px 0;
  background-color: #424242 !important;
  border-radius: 5px;
  margin-bottom: 20px;
}

.p-2 {
  padding: 0.5rem !important;
}
.py-24 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}
.text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}
.border-gray-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}
.border-vorange{
  --tw-border-opacity: 1 !important;
  border-color: #ff3200 !important;
}
.group:hover .group-hover\:border-vorange {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 50 0 / var(--tw-border-opacity)) !important;
}
.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity)) !important;
}
@media only screen and (min-width: 768px) {
  .p-10 {
    padding: 2.5rem !important;
  }
}
@media (min-width: 1280px) {
  .xl\:hidden {
    display: none !important;
  }
}
