body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.shimmer-card {
  border-radius: 8px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #6968683a !important;
  width: 100%;
}
.shimmer {
  background: linear-gradient(
    to right,
    #7a7979 8%,
    #6b6767 18%,
    #4e4c4c 33%
  ) !important;
  background-size: 1000px 100% !important;
  animation: shimmer 2.2s linear infinite forwards !important;
}
.shimmer-button {
  margin-bottom: 0px !important;
}
.Input,
.p-FauxInput {
  background-color: #262525 !important;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.rdrInputRangeInput {
  color: rgb(108, 118, 122) !important;
}
.rdrDateRangePickerWrapper {
  display: flex !important;
  flex-direction: row !important;
}

.model-inner-body{
  max-width: 600px !important;
  padding: 20px 20px 30px !important;
}
.model-inner-body h3{
  margin-bottom: 25px !important;
}
.model-inner-body .upl_plan div:nth-child(2) span{ 
  min-width: 165px;
  text-align: center;
  display: block;
}

@media only screen and (max-width: 600px) {
  .rdrDateRangePickerWrapper {
    flex-direction: column !important;
  }
  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }
}
@media(max-width:576px){
  .upl_plan{
    flex-direction: column;
  }
  .upl_plan div.ml-auto {
    margin-left: 0;
  }
  .upl_plan div.flex:nth-child(2){
    margin: 15px 0;
  }
  .model-inner-body h3 {
    margin-top: 15px !important;
  }
}


.payment-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.form-row {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.card-element {
  width: 100%;
  padding: 10px 0;
  background: transparent;
  border: none;
}

.card-errors {
  color: #fa755a;
  margin-top: 10px;
  font-size: 13px;
}

.button-row {
  display: flex;
  justify-content: space-between;
}

.submit-button, .cancel-button {
  width: 48%;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button {
  color: #fff;
  background-color: #32325d;
}

.submit-button:disabled {
  background-color: #aab7c4;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  background-color: #555abf;
}

.submit-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(50, 50, 93, 0.5);
}

.cancel-button {
  color: #fff;
  background-color: #f44336;
}

.cancel-button:hover {
  background-color: #e53935;
}

.cancel-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(244, 67, 54, 0.5);
}
